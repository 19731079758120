var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "text-truncate",
    attrs: {
      headers: _vm.headers,
      items: _vm.queryPreviewData.tableData,
      "sort-desc": [true, true],
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "v-toolbar",
              { staticClass: "mb-3", attrs: { flat: "", color: "white" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "primary--text font-weight-bold title" },
                      [_vm._v("Query Preview")]
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "500" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var dialog = ref.on
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var tooltip = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    icon: ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: ""
                                                        }
                                                      },
                                                      Object.assign(
                                                        {},
                                                        tooltip,
                                                        dialog
                                                      )
                                                    ),
                                                    [_vm._v("mdi-eye-outline")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("View query")])]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.sqlDialog,
                          callback: function($$v) {
                            _vm.sqlDialog = $$v
                          },
                          expression: "sqlDialog"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _c(
                                "div",
                                { staticClass: "primary--text" },
                                [
                                  _c("v-icon", { staticClass: "mr-1" }, [
                                    _vm._v("mdi-script-text-outline")
                                  ]),
                                  _vm._v("SQL Text")
                                ],
                                1
                              )
                            ]),
                            _c("v-divider", { staticClass: "mb-1" }),
                            _c("v-card-text", [
                              _vm._v(_vm._s(_vm.queryPreviewData.sql))
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.sqlDialog = false
                                      }
                                    }
                                  },
                                  [_vm._v("close")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _vm.latestQueryBuilderQueryData &&
                    _vm.latestQueryBuilderQueryData.query ===
                      _vm.queryPreviewData.sql
                      ? _c("QueryBuilderDialog", {
                          attrs: {
                            listItem: false,
                            setDataFromLastQuery: true,
                            outlinedActivationButton: true,
                            smallActivationButton: false,
                            buttonName: "open querybuilder"
                          },
                          on: {
                            querySubmitSuccess: function($event) {
                              return _vm.$emit("querySubmitSuccess")
                            }
                          }
                        })
                      : _vm._e(),
                    _c("GetTableData", {
                      attrs: { sqlText: _vm.queryPreviewData.sql }
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "font-weight-bold ml-1 caption",
                                      attrs: {
                                        color: "primary",
                                        outlined: "",
                                        text: "",
                                        loading: _vm.loading
                                      },
                                      on: { click: _vm.saveFullResults }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" }
                                      },
                                      [_vm._v("save")]
                                    ),
                                    _vm._v(" save full results ")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "Adds a CSV file to your files containing the full results of this query."
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "no-data",
        fn: function() {
          return [
            _c(
              "v-alert",
              {
                staticClass: "mt-3",
                attrs: { text: "", value: true, color: "info", icon: "info" }
              },
              [_vm._v(" No results for this query. ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }